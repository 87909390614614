import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from './redux/store';
import Blogs from "./screens/Blogs.jsx"
import AddPost from './screens/AddPost.jsx';
import EditPost from './screens/EditPost.jsx';
import AddArticle from './screens/AddArticle.jsx';
import Articles from './screens/Articles.jsx';
import EditArticle from './screens/EditArticle.jsx';
import AddVideo from './screens/AddVideo.jsx';
import Videos from './screens/Videos.jsx';
import EditVideo from './screens/EditVideo.jsx';
import Pdfs from './screens/Pdfs.jsx'
import AddPdf from './screens/AddPdf.jsx'
import EditPdf from './screens/EditPdf.jsx'
import AddOns from './screens/AddOns.jsx';
import EditOrg from './components/AddOns/EditOrg.jsx';
import AddOrg from './components/AddOns/AddOrg.jsx';
import AddService from './components/AddOns/AddService.jsx';
import EditService from './components/AddOns/EditService.jsx';
import Verify from './screens/Verify.jsx';
import ProtectedRoutes from './components/ProtectedRoutes.jsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes><Blogs /></ProtectedRoutes>
  },
  {
    path: "/add-post",
    element: <ProtectedRoutes><AddPost /></ProtectedRoutes>
  },
  {
    path: "/edit/:slug",
    element: <ProtectedRoutes><EditPost /></ProtectedRoutes>
  },
  {
    path: "/add-article",
    element: <ProtectedRoutes><AddArticle /></ProtectedRoutes>
  },
  {
    path: '/articles',
    element: <ProtectedRoutes><Articles /></ProtectedRoutes>
  },
  {
    path: '/edit-article/:slug',
    element: <ProtectedRoutes><EditArticle /></ProtectedRoutes>
  },
  {
    path: '/add-video',
    element: <ProtectedRoutes><AddVideo /></ProtectedRoutes>
  },
  {
    path: '/videos',
    element: <ProtectedRoutes><Videos /></ProtectedRoutes>
  },
  {
    path: '/edit-video/:id',
    element: <ProtectedRoutes><EditVideo /></ProtectedRoutes>
  },
  {
    path: '/pdfs',
    element: <ProtectedRoutes><Pdfs /></ProtectedRoutes>
  },
  {
    path: '/add-pdf',
    element: <ProtectedRoutes><AddPdf /></ProtectedRoutes>
  },
  {
    path: '/edit-pdf/:id',
    element: <ProtectedRoutes><EditPdf /></ProtectedRoutes>
  },
  {
    path: '/add-ons',
    element: <ProtectedRoutes><AddOns /></ProtectedRoutes>
  },
  {
    path: '/edit-org/:id',
    element: <ProtectedRoutes><EditOrg /></ProtectedRoutes>
  },
  {
    path: '/add-org',
    element: <ProtectedRoutes><AddOrg /></ProtectedRoutes>
  },
  {
    path: '/add-service',
    element: <ProtectedRoutes><AddService /></ProtectedRoutes>  
  },
  {
    path: '/edit-service/:id',
    element: <ProtectedRoutes><EditService /></ProtectedRoutes>
  },
  {
    path: '/verify',
    element: <Verify />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
