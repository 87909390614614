import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const isVerified = localStorage.getItem('token');
  const location = useLocation();

  if (!isVerified && location.pathname !== '/verify') {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoutes;