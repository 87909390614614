import React from 'react';
import { FaPlus } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = ({ blogTo, blogTitle, articleTo, articleTitle, videoTo, videoTitle, pdfTo, pdfTitle }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/verify');
    };

    return (
        <div className='relative w-screen h-[7vh] bg-[#e51a4b] px-4 py-0 shadow'>
            <nav className='w-full h-full flex justify-center items-center space-x-2'>
            <NavLink className='px-2 py-[1px] bg-white flex justify-center items-center gap-1 rounded h-fit flex-nowrap' to={blogTo} >
                    {blogTitle !== "All Posts" && <FaPlus color='#dc214c' />}
                    <span className='text-[#e51a4b] text-sm font-bold'>
                        {blogTitle}
                    </span>
                </NavLink>

                <NavLink className='px-2 py-[1px] bg-white flex justify-center items-center gap-1 rounded h-fit flex-nowrap' to={articleTo} >
                    {articleTitle !== "All Articles" && <FaPlus color='#dc214c' />}
                    <span className='text-[#e51a4b] text-sm font-bold'>
                        {articleTitle}
                    </span>
                </NavLink>

                <NavLink className='px-2 py-[1px] bg-white flex justify-center items-center gap-1 rounded h-fit flex-nowrap' to={videoTo} >
                    {videoTitle !== "All Videos" && <FaPlus color='#dc214c' />}
                    <span className='text-[#e51a4b] text-sm font-bold'>
                        {videoTitle}
                    </span>
                </NavLink>

                <NavLink className='px-2 py-[1px] bg-white flex justify-center items-center gap-1 rounded h-fit flex-nowrap' to={pdfTo} >
                    {pdfTitle !== "All Pdfs" && <FaPlus color='#dc214c' />}
                    <span className='text-[#e51a4b] text-sm font-bold'>
                        {pdfTitle}
                    </span>
                </NavLink>
                <NavLink className='px-2 py-[1px] bg-white flex justify-center items-center gap-1 rounded h-fit flex-nowrap' to={'/add-ons'} >
                    <span className='text-[#e51a4b] text-sm font-bold'>
                        Add Ons
                    </span>
                </NavLink>
            </nav>
            <button
                onClick={handleLogout}
                className='fixed bottom-4 right-4 px-4 py-2 bg-white text-[#e51a4b] font-bold rounded shadow border border-[#e51a4b] hover:bg-gray-200'
            >
                Logout
            </button>
        </div>
    );
};

export default Navbar;